import { useSuspenseQuery } from '@tanstack/react-query'
import * as _ from 'modules/util'
import UserAgent from 'resources/user-agent'
import Banner from './banner'

const UnsupportedBrowser = ({ dismissible, doDismissForDays }) => {
  const { data: userAgent } = useSuspenseQuery(UserAgent.queryOptions({ staleTime: Infinity }))

  const actions = [
    {
      children: userAgent.platform.hasAccessToSupportedBrowser
        ? 'Help me get a supported browser'
        : 'More information',
      color: 'green-fill',
      href: '/browser',
      target: '_blank',
    },
    _.when(dismissible, {
      children: 'Remind me later',
      onClick: doDismissForDays(7),
    }),
    _.when(dismissible, {
      children: 'Don’t tell me again',
      onClick: doDismissForDays(7300),
    }),
  ]

  return (
    <Banner actions={actions} type="warning">
      <strong>
        {userAgent.browser.identifier
          ? `We don’t test Cliniko in ${userAgent.browser.name}.`
          : 'We don’t recognize your browser.'}
      </strong>{' '}
      Some features may not work for you.
    </Banner>
  )
}

export default UnsupportedBrowser

import Button from 'components/button.legacy'
import Link from 'components/link.legacy'
import Popover from 'components/popover.legacy'
import Prose from 'components/prose.legacy'

const ErrorPage = () => (
  <Popover
    dismissible={false}
    pageLock
    title="Uh oh! Something happened and we are trying to fix it.">
    <Prose>
      We have recorded the error so that the Cliniko team can have a look. Try{' '}
      <Button link onClick={() => window.location.reload()}>
        refreshing the page
      </Button>
      . You can also check the <Link href="https://status.cliniko.com">Cliniko status page</Link>{' '}
      for updates.
    </Prose>
  </Popover>
)

export default ErrorPage

import { useSuspenseQuery } from '@tanstack/react-query'
import * as T from 'modules/time'
import * as _ from 'modules/util'
import * as W from 'modules/window'
import { useEffect, useState } from 'react'
import Boot from 'resources/boot'
import PublicBoot from 'resources/public-boot'
import Banner from './banner'

const InMaintenance = ({ isPublic }) => {
  const resource = isPublic ? PublicBoot : Boot
  const { data: { application } = {} } = useSuspenseQuery(
    resource.queryOptions({ staleTime: Infinity })
  )
  const [inMaintenance, setInMaintenance] = useState(false)

  const setInMaintenanceStatus = () => {
    _.thru(application, Boot.Application.isInMaintenanceWindow, setInMaintenance)
  }

  useEffect(setInMaintenanceStatus, [application])

  W.useInterval(setInMaintenanceStatus, 60 * 1000, {
    when: T.isInTheFuture(application?.maintenanceWindowEnd),
  })

  if (inMaintenance) {
    return (
      <Banner type="warning">
        <strong>
          We’re performing maintenance until {T.formatDateTime(application.maintenanceWindowEnd)}.
        </strong>{' '}
        You may notice trouble loading a page or two during this period, but it will be back online
        momentarily.
      </Banner>
    )
  }
}

export default InMaintenance

import Input from 'components/input'
import * as React from 'react'
import StyledPasswordInput from './password-input.legacy'

const PasswordInput = React.forwardRef(({ legacyStyle, ...props }, ref) => {
  if (legacyStyle) return <StyledPasswordInput ref={ref} {...props} />
  return <Input ref={ref} type="password" {...props} />
})

export default PasswordInput

import * as S from 'apps/cliniko/style'
import TransitionComponent from 'components/transition'

const Transition = props => (
  <TransitionComponent
    duration={150}
    enter={{ opacity: 1, transform: 'translate3d(0, 0, 0)' }}
    from={{ opacity: 0, transform: `translate3d(0, ${S.rem(30)}, 0)` }}
    {...props}
  />
)

export default Transition

import Input from 'components/input'
import { forwardRef } from 'react'
import LegacyEmailInput from './email-input.legacy'

const EmailInput = forwardRef(({ legacyStyle, ...props }, ref) =>
  legacyStyle ? (
    <LegacyEmailInput ref={ref} type="email" {...props} />
  ) : (
    <Input ref={ref} type="email" {...props} />
  )
)

export default EmailInput

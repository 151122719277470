import * as S from 'apps/cliniko/style'
import Button from 'components/button'
import StyledEmailInput from 'components/email-input'
import Prose from 'components/prose'
import emailChecker from 'modules/email-checker'
import { useEffectOnce } from 'modules/util'
import * as _ from 'modules/util'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDebouncedCallback } from 'use-debounce'
import FieldContainer from './field-container'
import LegacyFieldContainer from './field-container.legacy'
import LegacyLabel from './label.legacy'
import { setValueAsString, useUncontrolledInput } from './util'
import LegacyValidationMessage from './validation-message.legacy'
import * as validators from './validators'

const EmailInput = ({
  className,
  description,
  help,
  hint,
  legacyStyle,
  validate: validateIn,
  ...props
}) => {
  const { setValue } = useFormContext()

  const [suggestedEmail, setSuggestedEmail] = useState()
  const [hasBlurred, setHasBlurred] = useState()

  const checkEmail = value => {
    if (value.length > 0) {
      setSuggestedEmail(emailChecker(value))
    }
  }

  const debouncedCheckEmail = useDebouncedCallback(checkEmail, debounceTime)

  const validate = _.isObject(validateIn)
    ? { ...validateIn, email: validators.email }
    : _.isFunction(validateIn)
      ? { validateIn, email: validators.email }
      : validators.email

  const {
    label,
    onChange: onChangeIn,
    onBlur: onBlurIn,
    ...inputProps
  } = useUncontrolledInput({
    maxLength: 255,
    setValueAs: setValueAsString,
    validate,
    ...props,
  })

  const containerProps = {
    className,
    description,
    error: inputProps.error,
    help,
    hint,
    label,
    required: props.required,
  }

  const onSuggestionClick = () => {
    setSuggestedEmail('')
    setValue(props.name, suggestedEmail)
  }

  const onBlur = event => {
    onBlurIn?.(event)
    if (!hasBlurred) {
      setHasBlurred(true)
      checkEmail(event.target.value)
    }
  }

  const onChange = event => {
    onChangeIn?.(event)
    if (hasBlurred) debouncedCheckEmail(event.target.value)
  }

  useEffectOnce(() => {
    if (!legacyStyle) checkEmail(props.defaultValue || '')
  })

  if (legacyStyle) {
    return (
      <LegacyFieldContainer className={className} labelType={label.type}>
        <LegacyLabel {...label} />
        <div css={{ width: '100%' }}>
          <StyledEmailInput
            legacyStyle={legacyStyle}
            onChange={onChangeIn}
            onBlur={onBlurIn}
            {...inputProps}
          />
          <LegacyValidationMessage css={S.space.mt(1)} error={inputProps.error} />
        </div>
      </LegacyFieldContainer>
    )
  }

  return (
    <FieldContainer {...containerProps}>
      <StyledEmailInput
        aria-describedby={suggestedEmail ? `${inputProps.name}-suggestion` : null}
        onChange={onChange}
        onBlur={onBlur}
        {...inputProps}
      />
      {!inputProps['aria-invalid'] && !!suggestedEmail && (
        <EmailSuggestion
          name={inputProps.name}
          onClick={onSuggestionClick}
          suggestedEmail={suggestedEmail}
        />
      )}
    </FieldContainer>
  )
}

const EmailSuggestion = ({ name, suggestedEmail, onClick }) => (
  <Prose id={`${name}-suggesiton`} css={S.space.mt(1)}>
    Did you mean{' '}
    <Button link="inline" onClick={onClick}>
      {suggestedEmail}
    </Button>
    ?
  </Prose>
)

export const debounceTime = 500

export default EmailInput

import * as Icon from 'components/icon'
import Notice from 'components/notice'
import Prose from 'components/prose'
import * as _ from 'modules/util'

const Banner = ({ actions: actionsIn, children, icon: iconIn, type: typeIn, ...props }) => {
  const actions = _.castArray(actionsIn)
    .filter(Boolean)
    .map(action => ({ color: typeIn === 'warning' ? 'yellow-fill' : 'purple-fill', ...action }))
  const icon = iconIn || (typeIn === 'warning' && <Icon.Triangle />)
  const type = typeIn.concat('-banner')

  return (
    <Notice actions={actions} type={type} icon={icon} {...props}>
      <Prose>{children}</Prose>
    </Notice>
  )
}

export default Banner

import { notify as notifyHoneybadger } from 'apps/error-reporting'
import routes from 'apps/sign-in/routes'
import BrowserBanner from 'components/banners/browser'
import InMaintenance from 'components/banners/in-maintenance'
import ErrorBoundary from 'components/error-boundary'
import Messages from 'components/flash-messages.legacy/messages'
import QueryClientProvider from 'components/query-client-provider'
import Router from 'components/router'
import * as Flash from 'modules/flash'
import { Outlet } from 'modules/router'
import ErrorPage from 'pages/error'
import Layout from 'pages/sign-in/layout'
import { Suspense } from 'react'

const SignInApp = () => (
  <QueryClientProvider>
    <Router defaultOnError={notifyHoneybadger} routes={routes}>
      <Flash.Provider messages={window.initialFlashMessages} timeout={false}>
        <ErrorBoundary fallback={() => null}>
          <Messages types={['error', 'info', 'success', 'warning']} top />
          <Suspense fallback={null}>
            <BrowserBanner dismissible={false} />
            <InMaintenance isPublic />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary fallback={ErrorPage}>
          <Layout>
            <Outlet />
          </Layout>
        </ErrorBoundary>
      </Flash.Provider>
    </Router>
  </QueryClientProvider>
)

export default SignInApp

import * as S from 'apps/cliniko/style'
import DOMPurify from 'dompurify'
import * as _ from 'modules/util'
import * as R from 'ramda'

const Sanitize = ({ as: Component = 'div', children, ...props }) => (
  <Component
    css={styles}
    dangerouslySetInnerHTML={_.thru(children, DOMPurify.sanitize, R.objOf('__html'))}
    {...props}
  />
)

const styles = {
  color: 'inherit',
  lineHeight: S.rem(22),

  'a, .link': {
    textDecoration: 'underline',
  },

  'b, strong': {
    fontWeight: 'bold',
  },
}

export default Sanitize

import * as F from 'modules/form'
import { useState } from 'react'
import CheckboxAndSubmit from './checkbox-and-submit'
import Form from './form'
import { inputStyles } from './input-styles'
import Message from './message'
import PasskeyAuthButton from './passkey-auth-button'

const TwoFactorAuthForm = ({
  disabled: disabledIn,
  onSubmit,
  referralParam,
  state,
  passkeysEnabled,
}) => {
  const [disabled, setDisabled] = useState(false)

  return (
    <Form disabled={disabled || disabledIn} onSubmit={onSubmit} referralParam={referralParam}>
      {!!passkeysEnabled && (
        <PasskeyAuthButton
          onSubmit={onSubmit}
          setFormDisabled={setDisabled}
          state={state}
          name="passkey_two_factor"
          twoFactor={true}>
          Verify with a passkey
        </PasskeyAuthButton>
      )}
      <Message>Enter the verification code generated by your authenticator app.</Message>
      <F.HiddenField defaultValue={state.current.email} name="user.email" />
      <F.HiddenField defaultValue={state.current.password} name="user.password" />
      <F.HiddenField defaultValue={state.current.authToken} name="auth_token" />
      <F.HiddenField defaultValue={state.current.userId} name="user_id" />
      <F.TextInput
        autoFocus
        css={inputStyles}
        label="Verification code"
        legacyStyle
        name="user.otp_attempt"
        required
      />
      <CheckboxAndSubmit state={state} />
    </Form>
  )
}

export default TwoFactorAuthForm

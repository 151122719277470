import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'focus-visible'

import { migrate } from 'modules/au1-migrator'
import { createRoot } from 'react-dom/client'
import SignInApp from './app.js'

migrate()

const container = document.getElementById('cliniko-react')
const root = createRoot(container)
root.render(<SignInApp />)

import { useSuspenseQuery } from '@tanstack/react-query'
import * as T from 'modules/time'
import * as _ from 'modules/util'
import { useLocalStorage } from 'modules/window'
import * as R from 'ramda'
import UserAgent from 'resources/user-agent'
import ObsoleteBrowser from './obsolete-browser'
import ObsoletePlatform from './obsolete-platform'
import OutdatedBrowser from './outdated-browser'
import UnsupportedBrowser from './unsupported-browser'

const Browser = ({ dismissible }) => {
  const [dismissedBanners, setDismissedBanners] = useLocalStorage('dismissedBanners', {
    defaultTo: 0,
  })
  const { data: userAgent } = useSuspenseQuery(UserAgent.queryOptions({ staleTime: Infinity }))

  const doDismissForDays = R.thunkify(R.pipe(T.daysFromNow, setDismissedBanners))

  const showBrowserBanner =
    (!UserAgent.browser.isModern(userAgent) || !userAgent.platform.hasAccessToSupportedBrowser) &&
    (dismissible && !!dismissedBanners ? T.isInThePast(dismissedBanners) : true)

  const BrowserBanner = _.cond(
    [!userAgent?.platform?.hasAccessToSupportedBrowser, ObsoletePlatform],
    [UserAgent.browser.isObsolete(userAgent), ObsoleteBrowser],
    [UserAgent.browser.isOutdated(userAgent), OutdatedBrowser],
    [UserAgent.browser.isNotSupported(userAgent), UnsupportedBrowser],
    [R.always(null)]
  )

  if (showBrowserBanner) {
    return <BrowserBanner dismissible={dismissible} doDismissForDays={doDismissForDays} />
  }
}

export default Browser

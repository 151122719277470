// first we use encodeURIComponent to get percent-encoded UTF-8,
// then we convert the percent encodings into raw bytes which
// can be fed into btoa.
const encodeNonLatinCharactersAsBytes = str =>
  encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
    String.fromCharCode('0x' + p1))

export default (function (credentials) {
  let encodedPassword
  if (credentials.password != null) {
    encodedPassword = btoa(encodeNonLatinCharactersAsBytes(credentials.password))
  }

  return {
    'X-Verify-Password': encodedPassword,
    'X-Verify-Passkey': credentials.passkey
  }
})

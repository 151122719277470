import * as _ from 'modules/util'
import { fetchConditionalSigninCredential, fetchSigninCredential } from 'modules/webauthn'
import { useEffect, useState } from 'react'

const fetchCredential = async ({ abortController, isMediationEnabled, mediation }) => {
  if (mediation) {
    if (isMediationEnabled) {
      return fetchConditionalSigninCredential({ signal: abortController.signal })
    } else {
      return new Promise(() => {})
    }
  } else {
    return fetchSigninCredential()
  }
}

export const usePasskey = ({ isMediationEnabled, onError: onErrorIn, onSubmit: onSubmitIn }) => {
  const onSubmit = _.useEffectEvent(onSubmitIn)
  const onError = _.useEffectEvent(onErrorIn)

  const [mediation, setMediation] = useState(true)
  const [count, setCount] = useState(0)

  useEffect(() => {
    const abortController = new AbortController()

    fetchCredential({ abortController, isMediationEnabled, mediation })
      .then(credential => {
        if (credential) {
          return onSubmit({ credential })
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') {
          return
        }
        onError?.(error)

        if (mediation) {
          if (error.name === 'NotAllowedError' && /tls certificate/i.test(error.message)) {
            return
          }
          if (count < 4) {
            setCount(count => count + 1)
          }
        } else {
          setCount(0)
          setMediation(true)
        }
      })

    return () => {
      const abortError = new Error('Cancelling passkey mediation')
      abortError.name = 'AbortError'
      abortController.abort(abortError)
    }
  }, [count, isMediationEnabled, mediation, onError, onSubmit])

  return {
    fetchPasskey: () => {
      setMediation(false)
    },
  }
}

import * as _ from 'modules/util'
import { Fragment } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const Data = ({ as: Component = Fragment, children, defaultValue, watch, ...props }) => {
  const watchedData = useWatch({ defaultValue, name: watch })
  const { getValues } = useFormContext()
  const data = getValues()
  return <Component {...props}>{_.isFunction(children) ? children(data) : watchedData}</Component>
}

export default Data

import * as S from 'apps/cliniko/style'
import Prose from 'components/prose.legacy'

const Message = props => <Prose css={styles} lead {...props} />

const styles = {
  color: 'white',
  textAlign: 'center',
  ...S.legacy.space.mb(2.5),
}

export default Message

import * as F from 'modules/form'
import CheckboxAndSubmit from './checkbox-and-submit'
import Form from './form'
import { inputStyles } from './input-styles'
import Message from './message'

const EmailCodeAuthForm = ({ disabled: disabledIn, onSubmit, referralParam, state }) => (
  <Form disabled={disabledIn} onSubmit={onSubmit} referralParam={referralParam}>
    <Message>Enter the verification code we sent to your email</Message>
    <F.HiddenField defaultValue={state.current.email} name="user.email" />
    <F.HiddenField defaultValue={state.current.password} name="user.password" />
    <F.TextInput
      autoFocus
      css={inputStyles}
      label="Verification code"
      legacyStyle
      name="user.email_otp_attempt"
      required
    />
    <CheckboxAndSubmit state={state} />
  </Form>
)

export default EmailCodeAuthForm

import * as S from 'apps/cliniko/style'
import Transition from 'components/transition'
import AnimatedLogo from './animated-logo'
import MobileLogo from './mobile-logo'

const Logo = () => {
  const showAnimatedLogo = S.useIsPastBreakpoint(420)

  if (showAnimatedLogo) return <AnimatedLogo css={animatedLogoStyles} />

  return (
    <Transition css={{ transition: 'opacity 1.5s' }} from={{ opacity: 0 }} enter={{ opacity: 1 }}>
      <MobileLogo css={mobileLogoStyles} />
    </Transition>
  )
}

const animatedLogoStyles = {
  width: S.rem(344),
  margin: 'auto',
  svg: { fill: 'white' },
}

const mobileLogoStyles = [
  {
    width: S.rem(229),
    height: S.rem(56),
  },
  S.center,
]

export default Logo

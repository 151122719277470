import * as S from 'apps/cliniko/style'
import Link from 'components/link.legacy'
import * as F from 'modules/form'
import * as _ from 'modules/util'
import * as W from 'modules/window'
import { useState } from 'react'
import CheckboxAndSubmit from './checkbox-and-submit'
import Form from './form'
import { inputStyles } from './input-styles'
import Message from './message'
import PasskeyAuthButton from './passkey-auth-button'
import Transition from './transition'

const LoginForm = ({
  disabled: disabledIn,
  initialEmail,
  onSubmit,
  referralParam,
  state,
  welcomeMessage,
  passkeysEnabled,
}) => {
  const [disabled, setDisabled] = useState(false)

  return (
    <Form disabled={disabled || disabledIn} onSubmit={onSubmit} referralParam={referralParam}>
      <Transition>
        <Message>{welcomeMessage}</Message>
        {!!passkeysEnabled && (
          <PasskeyAuthButton
            isMediationEnabled={true}
            onSubmit={onSubmit}
            setFormDisabled={setDisabled}
            state={state}
          />
        )}
        <F.EmailInput
          autoComplete={`username${passkeysEnabled ? ' webauthn' : ''}`}
          autoFocus={!W.hasTouch && _.isBlank(initialEmail)}
          css={inputStyles}
          defaultValue={initialEmail}
          legacyStyle
          name="user.email"
          // eslint-disable-next-line react-compiler/react-compiler
          onChange={event => (state.current.email = event.target.value)}
          required
        />
      </Transition>
      <Transition delay={100}>
        <div css={{ position: 'relative' }}>
          <F.PasswordInput
            autoComplete={`password${passkeysEnabled ? ' webauthn' : ''}`}
            autoFocus={!W.hasTouch && _.isPresent(initialEmail)}
            css={[inputStyles, { input: S.legacy.space.pr(3) }]}
            legacyStyle
            name="user.password"
            onChange={event => (state.current.password = event.target.value)}
            required
          />
          <F.Data watch="user.email">
            {data => (
              <Link
                css={resetPasswordLinkStyles}
                href={resetPasswordLink(data?.user?.email || initialEmail)}>
                <span css={S.visuallyHidden}>Reset password</span>?
              </Link>
            )}
          </F.Data>
        </div>
      </Transition>
      <CheckboxAndSubmit state={state} />
    </Form>
  )
}

const resetPasswordLink = email =>
  email ? `/users/password/new?email=${email}` : '/users/password/new'

const resetPasswordLinkStyles = {
  boxSizing: 'content-box',
  color: 'white !important',
  lineHeight: S.rem(24),
  padding: S.rem(12),
  position: 'absolute',
  zIndex: 1,
  top: S.rem(20),
  right: 0,
  fontFamily: 'helvetica, sans-serif',
  textDecoration: 'none',
  fontWeight: 'bold',
  fontSize: S.rem(16),

  [S.legacy.media.medium]: {
    top: S.rem(-4),
  },
}

export default LoginForm

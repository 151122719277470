import { useQuery } from '@tanstack/react-query'
import * as S from 'apps/cliniko/style'
import Heading from 'components/heading.legacy'
import Prose from 'components/prose.legacy'
import UserAgent from 'resources/user-agent'
import Logo from './logo'

const Layout = ({ children }) => {
  const { data: userAgent } = useQuery(
    UserAgent.queryOptions({ staleTime: Infinity, throwOnError: false })
  )

  const cookieDisabled = (
    <div css={{ color: 'white' }}>
      <Heading level={1} css={S.legacy.space.mb(1)}>
        Please enable cookies
      </Heading>
      <Prose css={S.legacy.space.mb(1)}>
        Cliniko uses browser cookies to log you in. You won’t be able to access your account until
        you’ve enabled cookies.
      </Prose>

      {!!UserAgent.platform.isIOS(userAgent) && (
        <Prose css={S.legacy.space.mb(1)}>
          To enable cookies on your {userAgent.device.name}, open the <strong>Settings</strong> app,
          tap <strong>Safari</strong>, then <strong>Block Cookies</strong>, and choose{' '}
          <strong>Allow from Websites I Visit</strong>.
        </Prose>
      )}
    </div>
  )

  return (
    <div css={containerStyles}>
      <div css={signInStyles}>
        <header css={S.legacy.space.mb(2.5)}>
          <Logo />
          <h1 css={S.visuallyHidden}>Cliniko</h1>
        </header>
        {!window.navigator.cookieEnabled ? cookieDisabled : children}
      </div>
    </div>
  )
}

const containerStyles = {
  minHeight: '100%',
  backgroundColor: S.legacy.colors.primary,
}

const signInStyles = [
  {
    maxWidth: '92%',
    minHeight: S.rem(400),
    paddingTop: '10%',
    paddingBottom: '10%',

    [S.legacy.media.medium]: {
      paddingTop: '6%',
      paddingBottom: '6%',
      width: S.rem(540),
    },
  },
  S.center,
]

export default Layout

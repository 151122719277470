import { animated } from '@react-spring/web'
import * as S from 'apps/cliniko/style'
import TransitionList from 'components/transition-list'
import * as Flash from 'modules/flash'
import * as _ from 'modules/util'
import * as L from 'partial.lenses'
import Message from './message'

const Messages = ({ types, top }) => {
  const messages = Flash.useMessages(types)
  const setMessages = Flash.useSetMessages()

  return (
    <TransitionList
      as="div"
      css={wrapperStyles({ top })}
      enter={enterStyles({ top })}
      itemAs={Message}
      itemWrapper={animated.div}
      from={fromStyles({ top })}
      leave={leaveStyles({ top })}
      items={messages}
      setItems={setMessages}
    />
  )
}

const fromStyles = ({ top }) => ({
  height: 0,
  transform: `translateY(${top ? S.rem(-160) : '100%'})`,
  opacity: 0,
})

const enterStyles = ({ top }) => ({
  height: L.get('offsetHeight'),
  transform: `translateY(${top ? 0 : '0%'})`,
  opacity: 1,
})

const leaveStyles = ({ top }) => ({
  height: 0,
  transform: `translateY(${top ? S.rem(-160) : '100%'})`,
  opacity: 0,
})

const wrapperStyles = ({ top }) => [
  {
    zIndex: S.zIndex('rootElements', 'flashMessages'),
    textAlign: 'left',
    position: 'fixed',
    width: '100%',
  },
  _.ifElse(
    top,
    { top: 0 },
    {
      right: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column-reverse',

      [S.legacy.media.medium]: {
        right: S.rem(30),
        bottom: S.rem(30),
        width: 'auto',
      },
    }
  ),
  S.hideFromPrint,
]

export default Messages

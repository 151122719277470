import * as Request from 'modules/request'
import * as Resource from 'modules/resource'

const User = Resource.make('users')

User.confirmOptions = options => Request.postOptions({ ...options, url: '/users/confirmation' })

User.resetPasswordOptions = options => Request.postOptions({ ...options, url: '/users/password' })

User.signInOptions = options => Request.postOptions({ ...options, url: '/users/sign_in' })

User.unlockOptions = options => Request.postOptions({ ...options, url: '/users/unlock' })

export default User

import * as S from 'apps/cliniko/style'

export const inputStyles = [
  {
    flexDirection: 'column',

    [S.legacy.media.medium]: {
      alignItems: 'baseline',
      flexDirection: 'row',
    },

    input: {
      backgroundColor: S.legacy.colors.primaryDark,
      border: `1px solid ${S.legacy.colors.primaryDarker}`,
      color: 'white',
      paddingTop: S.rem(7),
      paddingRight: S.rem(10),
      paddingBottom: S.rem(8),
      paddingLeft: S.rem(10),
      position: 'relative',

      '&:disabled': {
        backgroundColor: S.legacy.colors.primaryDark,
        border: `1px solid ${S.legacy.colors.primaryDarker}`,
        cursor: 'not-allowed',
        opacity: 0.5,
      },
    },

    label: {
      color: 'white',
      float: 'left',
      fontWeight: 'normal',
      lineHeight: S.rem(24),
      padding: 0,
      position: 'relative',
      width: S.rem(145),

      '&:before': {
        content: "''",
        display: 'none',
        position: 'absolute',
        top: S.rem(24),
        borderBottom: `1px solid ${S.legacy.colors.primaryDark}`,
        width: '100%',

        [S.legacy.media.medium]: {
          display: 'block',
        },
      },
    },
  },
  S.legacy.space.mb(2),
]

import ConfirmUser from 'pages/sign-in/confirm-user'
import RequestPasswordReset from 'pages/sign-in/request-password-reset'
import SignInForm from 'pages/sign-in/sign-in-form'
import UnlockAccount from 'pages/sign-in/unlock-account'

const routes = [
  { path: '/users/confirmation/new', component: ConfirmUser },
  { path: '/users/password/new', component: RequestPasswordReset },
  { path: '/users/sign_in', component: SignInForm },
  { path: '/users/unlock/new', component: UnlockAccount },
]

export default routes
